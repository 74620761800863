<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save()"
          @archive="archive()"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <v-row>
              <v-col>
                <base-row
                    v-if="record.punchlist"
                    :label="$store.getters.translate('punchlist')"
                    :value="record.punchlist.name"
                    :link="true"
                    @click="$router.push('/punchlists/' + record.punchlist.id)"/>
                <template v-for="field in usual_fields">
                  <base-field
                      v-if="$store.getters.getUser.superuser || (field.name !== 'type' && field.name !== 'required_time' && field.name !== 'working_hours' &&
                      field.name !== 'causing_effect_hours' && field.name !== 'dynamic_causing_effect_hours' && field.name !== 'paperwork_hours')"
                      :isDisabled="!canEdit(record.id || 0)"
                      :key="field.name"
                      :value="record[field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                </template>
                <template v-if="$store.getters.getUser.superuser && record.required_time && record.working_hours">
                  <span class="mt-2 red--text">{{ getRequiredTime }}</span><br/><br/>
                  <!--<span class="mt-2 red--text" v-if="record.start_date">{{ getTotalTime }}</span><br/><br/>-->
                </template>
                <v-btn v-if="$store.getters.getUser.superuser" @click.stop="recountTime" block color="primary">
                  {{ $store.getters.translate("recount_time") }}
                </v-btn>
                <template v-if="record.custom_fields">
                  <template v-for="field in custom_fields">
                    <base-field
                        :isDisabled="!canEdit(record.id || 0)"
                        :key="field.name"
                        :value="record.custom_fields[field.name]"
                        :field="field"
                        @change="customFieldDataChanged"/>
                  </template>
                </template>
                <base-log-field class="mt-5" :model_type="page.model" :model_id="record.id"/>
                <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
              </v-col>
              <v-divider v-if="!$vuetify.breakpoint.smAndDown" vertical/>
              <v-col :cols="!$vuetify.breakpoint.smAndDown ? 6 : 12" style="overflow: auto; overflow-x: hidden">
                <v-row>
                  <v-col>
                    <v-btn @click.stop="viewReport" block color="primary">
                      {{ $store.getters.translate("progress_report") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-0">
                  <v-col class="pb-0">
                    <v-switch
                        v-model="filter_by_tags"
                        :label="$store.getters.translate('filter_by_tags')"/>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0">{{ $store.getters.translate('progress') }}: {{ Math.floor((stats_rows.total_commissioned * 100) / stats_rows.total_amount) }}%</v-col>
                  <v-col class="pt-0">{{ $store.getters.translate('nr_documents') }}: {{ stats_rows.total_amount }}</v-col>
                  <v-col class="pt-0">{{ $store.getters.translate('nr_commissioned') }}: {{ stats_rows.total_commissioned }}</v-col>
                </v-row>
                <v-row dense v-for="(item, type, index) in stats_rows" :key="index">
                  <v-col v-if="type !== 'total_amount' && type !== 'total_commissioned' && type !== 'total_status_not_set' &&
                                type !== 'total_assembled' && type !== 'total_disassembled' && type !== 'total_tightened' && type !== 'total_n_a'">
                    <v-row dense v-for="(value, subtype, index) in item" :key="index">
                      <v-col>
                        <v-row>
                          <v-col>
                            {{ type }} > {{ subtype }}
                            <v-icon class="mr-2" @click="viewDocumentReport(type, subtype)">mdi-file-document</v-icon>
                            <v-spacer/>
                            <small>{{ value.commissioned }} {{ $store.getters.translate('commissioned') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-0">
                          <v-col class="pt-0">
                            <v-progress-linear :value="calculatePercentage(value)" color="#45a164" height="45" lighten background-opacity="0.4">
                              <template v-slot:default="{ value }">
                                <small style="color: white">{{ Math.floor(value) }}%</small>
                              </template>
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                        <template v-if="type === 'Certificates' && subtype === 'Certificate'">
                          <!--TIGHTEDED-->
                          <v-row class="mt-0">
                            <v-col>
                              {{ $store.getters.translate('flange_tightened') }}
                              <v-spacer/>
                              <small>{{ stats_rows['total_tightened'] }} {{ $store.getters.translate('tightened') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col class="pt-0">
                              <v-progress-linear :value="calculateFlangePercentage(stats_rows['total_tightened'], value.amount)" color="#f1c40f" height="45" lighten background-opacity="0.4">
                                <template v-slot:default="{ value }">
                                  <small style="color: white">{{ Math.floor(value) }}%</small>
                                </template>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                          <!--ASSEMBLED-->
                          <v-row class="mt-0">
                            <v-col>
                              {{ $store.getters.translate('flange_assembled') }}
                              <v-spacer/>
                              <small>{{ stats_rows['total_assembled'] }} {{ $store.getters.translate('assembled') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col class="pt-0">
                              <v-progress-linear :value="calculateFlangePercentage(stats_rows['total_assembled'], value.amount)" color="#e03e2d" height="45" lighten background-opacity="0.4">
                                <template v-slot:default="{ value }">
                                  <small style="color: white">{{ Math.floor(value) }}%</small>
                                </template>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                          <!--DISASSEMBLED-->
                          <v-row class="mt-0">
                            <v-col>
                              {{ $store.getters.translate('flange_disassembled') }}
                              <v-spacer/>
                              <small>{{ stats_rows['total_disassembled'] }} {{ $store.getters.translate('disassembled') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col class="pt-0">
                              <v-progress-linear :value="calculateFlangePercentage(stats_rows['total_disassembled'], value.amount)" color="#3598db" height="45" lighten background-opacity="0.4">
                                <template v-slot:default="{ value }">
                                  <small style="color: white">{{ Math.floor(value) }}%</small>
                                </template>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                          <!--N/A-->
                          <v-row class="mt-0">
                            <v-col>
                              N/A
                              <v-spacer/>
                              <small>{{ stats_rows['total_n_a'] }} N/A - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col class="pt-0">
                              <v-progress-linear :value="calculateFlangePercentage(stats_rows['total_n_a'], value.amount)" color="#cc7a00" height="45" lighten background-opacity="0.4">
                                <template v-slot:default="{ value }">
                                  <small style="color: white">{{ Math.floor(value) }}%</small>
                                </template>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                          <!--NOT SET-->
                          <v-row class="mt-0">
                            <v-col>
                              {{ $store.getters.translate('flange_status_not_set') }}
                              <v-spacer/>
                              <small>{{ stats_rows['total_status_not_set'] }} {{ $store.getters.translate('status_not_set') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col class="pt-0">
                              <v-progress-linear :value="calculateFlangePercentage(stats_rows['total_status_not_set'], value.amount)" color="#a11203" height="45" lighten background-opacity="0.4">
                                <template v-slot:default="{ value }">
                                  <small style="color: white">{{ Math.floor(value) }}%</small>
                                </template>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- DOCUMENT TYPES -->
                <v-row v-if="$store.getters.getUser.superuser" class="mt-0 mb-0">
                  <v-col class="pb-0">
                    <v-card :loading="loading" :disabled="loading">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header style="height: 48px">
                            <v-toolbar class="no-left-padding" dense flat>
                              <v-toolbar-title style="font-size: 13pt" class="ma-0 pa-0">
                                {{ $store.getters.translate('document_types') }}
                              </v-toolbar-title>
                              <v-spacer/>
                              <v-btn icon @click="updateDocumentTypes" color="primary">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </v-toolbar>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                <tr>
                                  <th>{{ $store.getters.translate('type') }}</th>
                                  <th>{{ $store.getters.translate('subtype') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(document_type, index) in document_types" :key="index">
                                  <td>
                                    <v-select
                                        v-model="document_type.type"
                                        :items="$lodash.sortBy(types, 'text')"
                                        item-value="value"
                                        item-text="text"
                                        :disabled="loading"
                                        :loading="loading"/>
                                  </td>
                                  <td>
                                    <v-select
                                        v-model="document_type.subtype"
                                        :items="$lodash.sortBy(subtypes, 'text')"
                                        item-value="value"
                                        item-text="text"
                                        :disabled="loading"
                                        :loading="loading"/>
                                  </td>
                                </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <asset-panel ref="platforms" :editable="canEdit(record.id || 0)" :module_name="page.name"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="documents" :page="page" :parent_module_id="record.id" relation_name="documents" @create="createDocument" @edit="preview" @change="load" @download="exportDocuments"/>
          <base-table-includable ref="equipment" :page="page" :parent_module_id="record.id" relation_name="equipment"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <base-report modal_name="base_report" ref="base_report"/>
    <base-document-report modal_name="base_document_report" ref="base_document_report" @edit="preview"/>
    <create-document modal_name="create_document2" ref="create_document2" @edit="goToAsset" @refresh="load()"/>
    <document-preview modal_name="document_preview2" ref="document_preview2" @edit="goToAsset" @refresh="load();stats()" @create="createRevision"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import AssetPanel from "../../components/commonComponents/AssetPanelV2";
import CreateDocument from "../documents/Create";
import DocumentPreview from "../../components/commonComponents/DocumentPreview.vue";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseLogField from "../../components/commonComponents/BaseLogField";
import BaseReport from "../../components/commonComponents/BaseReport";
import BaseDocumentReport from "../../components/commonComponents/BaseDocumentReport";
import Pusher from "pusher-js";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseTableIncludable,
    BaseBreadcrumb,
    BaseTabPanel,
    AssetPanel,
    CreateDocument,
    DocumentPreview,
    BaseLogField,
    BaseReport,
    BaseDocumentReport
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("projects"),
        name: "projects",
        model: "project"
      },
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      all_stats: [],
      stats_rows: [],
      filter_by_tags: true,
      document_types: [],
      types: [],
      subtypes: [],
    };
  },
  created() {
    this.load();
    this.stats();
    this.getTypesAndSubtypes();
    this.loadDocumentTypes();

    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
    });
    Pusher.logToConsole = false;

    pusher.subscribe("exportDocuments");
    pusher.bind("export-documents", (data) => {
        this.$toasted.info('<a style="color:white" href="' + data.response.link + '" target="_blank"><strong>Download exported documents</strong></a>',
            {timeout: 999999999, dismissable: true, multiLine: true, queueable: false, showClose: true, closeText: "Close", closeIcon: "close"}
        );
    });
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      if(this.record.platformName !== "") {
        this.record.platform_name = this.record.platformName;
      }
      this.$refs.platforms.setPlatformId(this.record.platform_id, 'parent');
      this.$refs.platforms.tags = [];
      this.record.tags.forEach((tag) => {
        this.$refs.platforms.project_tags.push(tag);
        this.$refs.platforms.tags.push(tag);
      });
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.documents.records = this.record.documents;
      this.$refs.equipment.records = this.record.equipment;
      this.$refs.documents.selected = [];
      this.$refs.equipment.selected = [];
    },
    exportDocuments(selected) {
      var file_ids = [];
      selected.forEach((document) => {
        file_ids.push(document.file_id);
      });
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/export/documents", file_ids)
          .then((response) => {
            this.loading = false;
            if (response.status == 200) {
              this.$toasted.success(this.$store.getters.translate("export_in_progress"));
            } else {
              var errorMessage = response.data.message;
              var errors = Object.keys(response.data.message);
              this.$toasted.error([errorMessage[errors[0]]]);
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    goToAsset(asset_id) {
      this.$router.push({name: "Platforms", params: { asset_id: asset_id }});
    },
    async stats() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/projects/project-stats/" + this.$route.params.id)
          .then((response) => {
            this.loading = false;
            this.all_stats = response.data;
            this.stats_rows = this.all_stats["with_tags"];
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async recountTime() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/projects/recount-time", this.record)
          .then((response) => {
            this.loading = false;
            if (response.status == 200) {
              this.$toasted.success(this.$store.getters.translate("successfully_saved"));
              this.load();
            } else {
              var errorMessage = response.data.message;
              var errors = Object.keys(response.data.message);
              this.$toasted.error([errorMessage[errors[0]]]);
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async loadDocumentTypes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/get-document-types/" + this.$route.params.id)
          .then((response) => {
            this.loading = false;
            this.document_types = [];
            response.data.forEach((document_type) => {
              this.document_types.push({
                type: document_type.type,
                subtype: document_type.subtype,
              });
            });
            this.document_types.push({
              type: null,
              subtype: null,
            });
            this.document_types.push({
              type: null,
              subtype: null,
            });
            this.document_types.push({
              type: null,
              subtype: null,
            });
          }).catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateDocumentTypes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-document-types", {project_id: this.record.id, document_types: this.document_types})
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("success"));
            this.stats();
            this.loadDocumentTypes();
          }).catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    viewReport() {
      this.$refs.base_report.load(this.record, this.all_stats);
    },
    viewDocumentReport(type, subtype) {
      this.$refs.base_document_report.type = type;
      this.$refs.base_document_report.subtype = subtype;
      this.$refs.base_document_report.load(this.record);
    },
    calculatePercentage(item) {
      return (item.commissioned * 100) / item.amount;
    },
    calculateFlangePercentage(flange_amount, total_amount) {
      return (flange_amount * 100) / total_amount;
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            /*if(response.data.asset_not_deleted === true) {
              this.$toasted.error("Platform can't be deleted because it is a part of another project");
            }
            if(response.data.asset_document_not_deleted === true) {
              this.$toasted.error("Document can't be deleted because it is a part of another platform");
            }*/
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createDocument() {
      this.$refs.create_document2.load(null, null, this.record.tags);
    },
    preview(document) {
      this.$refs.document_preview2.load(document.id);
    },
    createRevision(document) {
      this.$refs.create_document2.load(document, null, null);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getTypesAndSubtypes() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/typesandsubtypes")
          .then((response) => {
            this.types = [];
            this.subtypes = [];
            this.types.push({
              text: '',
              value: '',
            });
            response.data.forEach((option) => {
              if(option.type === 'type') {
                this.types.push({
                  text: option.name,
                  value: option.name,
                });
              }
              else if(option.type === 'subtype') {
                this.subtypes.push({
                  text: option.name,
                  value: option.name,
                });
              }
            });
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
    getRequiredTime() {
      var additional_time = 0;
      if(this.record.causing_effect_hours) {
        additional_time += parseFloat(this.record.causing_effect_hours.replace(",", "."));
      }
      if(this.record.dynamic_causing_effect_hours) {
        additional_time += parseFloat(this.record.dynamic_causing_effect_hours.replace(",", "."));
      }
      if(this.record.paperwork_hours) {
        additional_time += parseFloat(this.record.paperwork_hours.replace(",", "."));
      }
      var required_time = parseInt(this.record.required_time.replace(",", ".")) + additional_time;
      var required_days = Math.ceil(required_time / this.record.working_hours);
      return "Time formula: " + required_time + " required hours / " + this.record.working_hours + " working hours = " + required_days + " required days";
    },
    getTotalTime() {
      var additional_time = 0;
      if(this.record.causing_effect_hours) {
        additional_time += parseFloat(this.record.causing_effect_hours.replace(",", "."));
      }
      if(this.record.dynamic_causing_effect_hours) {
        additional_time += parseFloat(this.record.dynamic_causing_effect_hours.replace(",", "."));
      }
      if(this.record.paperwork_hours) {
        additional_time += parseFloat(this.record.paperwork_hours.replace(",", "."));
      }
      var required_time = parseInt(this.record.required_time.replace(",", ".")) + additional_time;
      var required_days = Math.ceil(required_time / this.record.working_hours);
      var weekends = 0;
      if (required_days + moment(this.record.start_date).isoWeekday() > 96) {
        weekends = 38;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 91) {
        weekends = 36;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 86) {
        weekends = 34;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 81) {
        weekends = 32;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 76) {
        weekends = 30;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 71) {
        weekends = 28;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 66) {
        weekends = 26;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 61) {
        weekends = 24;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 56) {
        weekends = 22;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 51) {
        weekends = 20;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 46) {
        weekends = 18;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 41) {
        weekends = 16;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 36) {
        weekends = 14;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 31) {
        weekends = 12;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 26) {
        weekends = 10;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 21) {
        weekends = 8;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 16) {
        weekends = 6;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 11) {
        weekends = 4;
      } else if (required_days + moment(this.record.start_date).isoWeekday() > 6) {
        weekends = 2;
      }
      let total_days = required_days + weekends;
      return "Total time: " + required_days + " required days + " + weekends + " weekends = " + total_days + " days";
    },
  },
  watch: {
    filter_by_tags: {
      handler() {
        if (this.filter_by_tags) {
          this.stats_rows = this.all_stats["with_tags"];
        }
        else {
          this.stats_rows = this.all_stats["all"];
        }
      },
    }
  },
};
</script>

<style>
.v-label {
  font-size: 14px
}
</style>